<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { roomTypeEnum } from "@/components/enums.js";
import Multiselect from "vue-multiselect";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Oda Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Oda Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Oda Yönetimi",
          href: "/room",
        },
        {
          text: "Oda Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      roomTypeEnum,
      values: [],
      isButton: false,
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    showAlertSuccess(){
      this.isAlertVisibleSuccess = true
    },
    showAlertDanger(){
      this.isAlertVisibleDanger = true
    },
    getData(){
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/room/${itemId}`;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSubmit() {
      this.isButton = true;
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/room/${itemId}`;
      const data = {
        room_name: this.values.room_name,
        room_type: this.values.room_type.value,
      };
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isButton = false
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class>
          <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
          <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="brand_name">Oda Adı:</label>
                    <input
                      v-model="values.room_name"
                      type="text"
                      class="form-control"
                      id="brand_name"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="roomType">Oda Tipi:</label>
                    <multiselect v-model="values.room_type" :options="roomTypeEnum" label="text">
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary" :disabled="isButton">Güncelle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
